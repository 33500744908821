import { IsGTMLoad, TabDetails, ValueObject } from "./types";
import pick from "lodash.pick";
import mapKeys from "lodash/mapKeys";
import { memoryStorage } from "./storage";
import { REQUIRED_PERMISSION_COUNTRIES } from "@RHCommerceDev/utils/constants";
import yn from "yn";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { formatCurrency } from "@RHCommerceDev/utils/currencyHelpers";
import { setCookieValue } from "hooks/useCookiesWithPermission";
import { getCookie } from "@RHCommerceDev/utils/cookies";

export const compare = (a: ValueObject, b: ValueObject): boolean =>
  JSON.stringify(Object.entries(a).sort()).toLowerCase() ===
  JSON.stringify(Object.entries(b).sort()).toLowerCase();

export const getAnalyticsAttrsFromProps = (props: ValueObject): ValueObject => {
  return pick(props, [
    "authoringName",
    "bannerName",
    "bannerType",
    "ctaName",
    "positionInfo"
  ]);
};

export const isOnProductPage = () =>
  !processEnvServer &&
  (location?.pathname?.includes("/products.jsp") ||
    location?.pathname?.includes("/pg"));

export const isOnSearchPage = () =>
  !processEnvServer && location?.pathname?.includes("/search");

export const getAnalyticsDataAttrsFromProps = (
  props: ValueObject
): ValueObject => {
  const data = getAnalyticsAttrsFromProps(props);
  return (
    data &&
    mapKeys(data, (v, key) => {
      return `data-${key.toLowerCase()}`;
    })
  );
};

export function isGDPRCompliantORCookieAccepted(): boolean {
  if (processEnvServer) {
    return false;
  }
  try {
    const sessionData = memoryStorage.getItem("analytics-sessionDetails") || {};
    const akamaiCountryCode = sessionData?.rhUser?.akamaiCountryCode;
    const analyticsCookie = sessionData?.cookiePreferences?.analyticsCookie;
    const cookieRules = sessionData?.cookiePreferences?.cookieRules;
    const thirdPartyAnalyticsCookie =
      sessionData?.cookiePreferences?.thirdPartyAnalyticsCookie;
    const isAnalyticsAccepted =
      !!yn(analyticsCookie) && !!yn(thirdPartyAnalyticsCookie);
    const countryToCheck =
      akamaiCountryCode === "GB" ? akamaiCountryCode : cookieRules;
    const isCountryRequiresPermission = REQUIRED_PERMISSION_COUNTRIES?.includes(
      countryToCheck || ""
    );

    if (!isCountryRequiresPermission || isAnalyticsAccepted) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function isGTMLoaded(): IsGTMLoad {
  if (processEnvServer) {
    return {
      isGTMLoaded: false
    };
  }
  const isGTMLoad = memoryStorage.getItem("isGTMLoad");
  const isCountryChanged = memoryStorage.getItem("isCountryChanged");
  const isGTMLoaded = Boolean(sessionStorage.getItem("isGTMLoaded"));

  if (isCountryChanged) {
    return {
      isGTMLoaded: false
    };
  }

  if (isGTMLoaded || isGTMLoad || isGDPRCompliantORCookieAccepted()) {
    return {
      isGTMLoaded: true
    };
  }

  return {
    isGTMLoaded: false
  };
}

export function getPriceData(
  userType,
  pricing,
  normalPriceKey,
  inStockProductCard
) {
  const price = Array.isArray(pricing?.[normalPriceKey])
    ? pricing?.[normalPriceKey][0] ?? 0
    : pricing?.[normalPriceKey] ?? 0;
  const priceData =
    userType === "TRADE" || userType === "CONTRACT"
      ? {
          price:
            userType === "TRADE" ? pricing?.tradePrice : pricing?.contractPrice,
          type: userType?.toLowerCase()
        }
      : pricing?.salePrice && pricing?.salePrice < price
      ? { price: pricing?.salePrice, type: "sale" }
      : {
          price: price,
          type: normalPriceKey === "listPrice" ? "regular" : "member"
        };
  const stockOrSale = inStockProductCard
    ? pricing?.onSale
      ? "OnSale"
      : "InStock"
    : "Standard";
  return { priceData, stockOrSale };
}

export const getGACookieObject = () => {
  const gaCookieRegx = /^_g/;
  const cookiesObject = document.cookie
    ?.split(";")
    .reduce((cookies, cookie) => {
      const [name, value] = cookie.split("=").map(c => c.trim());
      if (gaCookieRegx.test(name)) {
        cookies[name] = decodeURIComponent(value);
      }
      return cookies;
    }, {});
  return cookiesObject;
};

export const preConfigureProduct = ({
  fullSkuId,
  preConfiguredSku,
  relatedProductSkuId
}) => {
  let productConfig = "";
  if (fullSkuId?.startsWith("M")) {
    productConfig = "MSKU";
  } else if (
    (fullSkuId && fullSkuId === preConfiguredSku) ||
    relatedProductSkuId
  ) {
    productConfig = "Preconfigured";
  }
  return productConfig;
};

export const formatShippingValue = ({
  freightOptions,
  pageContent,
  currencyCode,
  value
}) => {
  const { ground = 0, second = 0, next = 0 } = freightOptions;
  const groundPrice = ground
    ? formatCurrency(ground / 100, currencyCode, true)
    : pageContent?.FREE;
  const secondPrice = formatCurrency(second / 100, currencyCode, true);
  const nextPrice = formatCurrency(next / 100, currencyCode, true);
  const shippingValue = {
    ground: `${pageContent?.THREE_SEVEN_DAYS} ${groundPrice}`,
    second: `${pageContent?.TWO_DAYS} ${secondPrice}`,
    next: `${pageContent?.ONE_DAY} ${nextPrice}`
  };
  if (!processEnvServer) {
    memoryStorage.setItem("selectedValue", shippingValue[value || "ground"]);
  }
};

export const calculateTotalPrice = (lineitems: LineItemType[] = []) => {
  return lineitems?.reduce(
    (acc, cur) => acc + (cur?.price?.totalPrice || 0),
    0
  );
};

const RH_TAB_COUNTER_KEY = "app_rh_open_tabs";

export const getTabDetails = (): TabDetails => {
  const cookieValue = getCookie(RH_TAB_COUNTER_KEY);
  if (cookieValue) {
    try {
      return typeof cookieValue === "string"
        ? JSON.parse(cookieValue)
        : cookieValue;
    } catch (error) {
      console.error("Error parsing tab details from cookie:", error);
    }
  }
  return { tabCount: 0, isTrigger: false, previousTabCount: 0 };
};

const setTabCount = (tabDetails: TabDetails) => {
  setCookieValue({
    storageKey: RH_TAB_COUNTER_KEY,
    value: JSON.stringify(tabDetails)
  });
};

export const setEventTriggered = () => {
  const { tabCount } = getTabDetails();
  const tabDetails: TabDetails = {
    tabCount,
    isTrigger: true,
    previousTabCount: tabCount
  };
  setTabCount(tabDetails);
};

export const incrementTabCount = () => {
  const currentCount = getTabDetails();
  const newCount = currentCount.tabCount + 1;
  const tabDetails: TabDetails = {
    tabCount: newCount,
    isTrigger: currentCount.previousTabCount === newCount,
    previousTabCount: currentCount.previousTabCount
  };
  setTabCount(tabDetails);
  return newCount;
};

export const decrementTabCount = () => {
  const currentCount = getTabDetails();
  const newCount = Math.max(currentCount.tabCount - 1, 0);
  const tabDetails: TabDetails = {
    tabCount: newCount,
    isTrigger: currentCount.isTrigger,
    previousTabCount: currentCount.previousTabCount
  };
  setTabCount(tabDetails);
  return newCount;
};
