import { processEnvServer } from "hooks/useSsrHooks";
import { AppEnv } from "@RHCommerceDev/utils-app-env";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { ESTORE_APP_ID } from "utils/constants";
import { getReqContext } from "utils/reqContext";

const env = globalThis._env;

export type CustomGlobals = AppEnv;

export type CustomGlobalKeys = keyof AppEnv;

export interface GlobalGetters {
  _env: Function;
}

export interface CachedGlobals {
  _env?: AppEnv;
}

const parseVal = (val: string) =>
  val === "true" ? true : val === "false" ? false : val;

export function getEnvOrCookie(key: string): string | boolean {
  let cookiesArray;
  if (typeof document !== "undefined") {
    cookiesArray = document?.cookie?.split(";") ?? [];
  }

  const [_, value] = (cookiesArray?.find(c => c?.includes(`${key}=`)) || "")
    .trim()
    .split("=");
  return parseVal(value ? value : (globalThis._env || {})[key]);
}

/**
 *
 * @param primaryFlag string | only use primaryFlag if preferredFlag is undefined
 * @param preferredFlag string | use preferredFlag if defined
 * @returns boolean
 */
export function getPreferredFlag(
  primaryFlag: string,
  preferredFlag: string
): boolean {
  const primary = getEnvOrCookie(primaryFlag);
  const preferred = getEnvOrCookie(preferredFlag);
  /** it is important the the preferred flag is undefined so primary takes over */
  return Boolean(preferred !== undefined ? preferred : primary);
}

export function mapKeysToEnvOrCookie(keys: (keyof AppEnv)[]): {
  [key in keyof AppEnv]?: string;
} {
  return keys.reduce((prev, key) => {
    prev[key] = getEnvOrCookie(key);
    return prev;
  }, {});
}

export const globalGetters: GlobalGetters = {
  _env: (loc?: string): AppEnv => {
    if (process.env.NODE_ENV === "test") {
      // @ts-ignore
      return process.env;
    } else {
      // server will provide "loc"
      // if !server, then "loc" will be undefined and should be set to window.location.origin for client
      let windowOrigin;
      if (typeof window !== "undefined") {
        windowOrigin = window.location;
      }
      const location = loc || windowOrigin;

      let cookiesArray;
      if (typeof document !== "undefined") {
        cookiesArray = document?.cookie?.split(";") ?? [];
      }

      let featureSSR: boolean | undefined = undefined;
      if (
        cookiesArray?.filter(cookie => cookie?.includes("FEATURE_SSR=true"))
          .length !== 0
      ) {
        featureSSR = true;
      }
      if (
        cookiesArray?.filter(cookie => cookie?.includes("FEATURE_SSR=false"))
          .length !== 0
      ) {
        featureSSR = false;
      }

      let forceClientRender;
      if (typeof window !== "undefined") {
        forceClientRender = window.location.href?.includes("&ct=true");
      }
      const forceCSR = forceClientRender;

      let featureAemSSR: boolean | undefined = undefined;
      if (
        cookiesArray?.filter(cookie => cookie?.includes("FEATURE_AEM_SSR=true"))
          .length !== 0
      ) {
        featureAemSSR = true;
      }
      if (
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_AEM_SSR=false")
        ).length !== 0
      ) {
        featureAemSSR = false;
      }

      const featureSchedulingVirtualAgent =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_SCHEDULING_VIRTUAL_AGENT=true")
        ).length !== 0;

      const featureAemRenderControl =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_AEM_RENDER_CONTROL=true")
        ).length !== 0;

      const featureWistiaOptimizations =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_WISTIA_OPTIMIZATIONS=true")
        )?.length !== 0;

      const featureEmailMarketingPopupNA =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_EMAIL_MARKETING_POPUP_NA=true")
        )?.length !== 0;

      const featureEmailMarketingFooterNA =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_EMAIL_MARKETING_FOOTER_NA=true")
        ).length !== 0;

      const featureEmailMarketingFooterUK =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_EMAIL_MARKETING_FOOTER_UK=true")
        ).length !== 0;

      const featureUseQuantity =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_USE_QUANTITY=true")
        ).length !== 0;

      const featureCgCarousel =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_CG_CAROUSEL=true")
        ).length !== 0;

      const featureEuPriceFormat =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_EU_PRICE_FORMAT=true")
        ).length !== 0;

      const featureIFramePrint =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_IFRAME_PRINT=true")
        ).length !== 0;

      const featureTailwindIcon =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_TAILWIND_ICON=true")
        ).length !== 0;

      const featurePostalCodeDailogV2 =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_POSTALCODE_DIALOG_V2=true")
        ).length !== 0;

      const featureShippingButtonV2 =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_CHECKOUT_SHIPPING_BUTTON_V2=true")
        ).length !== 0;

      const featureStytch =
        cookiesArray?.filter(cookie => cookie?.includes("FEATURE_STYTCH=true"))
          .length !== 0;

      const featureAtcDisableFix =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_ATC_DISABLE_FIX=true")
        ).length !== 0;

      const featureRHPromoBanner =
        cookiesArray?.filter(cookie =>
          cookie?.includes("FEATURE_RH_PROMO_BANNER=true")
        ).length !== 0;

      const keys: (keyof AppEnv)[] = [
        "ABTEST_PG_2UP",
        "ANALYTICS_CLEANUP_DATALAYER",
        "APP_ID",
        "FEATURE_ADDON_MESSAGING",
        "FEATURE_ADD_ITEM_CREATE_CART",
        "FEATURE_AEM_SSR",
        "FEATURE_APP_REFRESH_POLLING",
        "FEATURE_CANONICAL_URLS",
        "FEATURE_CART_CONCIERGE_SAVED_PAYMENT_METHODS",
        "FEATURE_CART_ID_FOR_SESSION",
        "FEATURE_CART_ID_ATOM",
        "FEATURE_CART_PRICE_DEBUG",
        "FEATURE_CART_PRICE_DISPLAY",
        "FEATURE_CART_REMOVE_SHIPPING_AMOUNT",
        "FEATURE_CART_RESTRICTIONS",
        "FEATURE_CART_SPO",
        "FEATURE_CART_SPLIT_PAYMENT",
        "FEATURE_CG_CAROUSEL",
        "FEATURE_CG_COLORIZATION",
        "FEATURE_CG_DYNAMIC_BANNER_MESSAGE",
        "FEATURE_SBR_ENHANCED_GRID",
        "FEATURE_EMBLA_CAROUSEL",
        "FEATURE_PERFORMANCE_OPTIMIZATION_TESTING",
        "FEATURE_CG_PAGINATION",
        "FEATURE_CG_PAGINATION_LOAD_ALL",
        "FEATURE_CG_PAGINATION_LOAD_FULL_PAGE",
        "FEATURE_CHECKOUT_REDIRECT_DISABLE",
        "FEATURE_CMR",
        "FEATURE_COUNTRY_NAV_MENU",
        "FEATURE_DISPLAY_MISSING_AEM_PAGE_CONTENT_KEYS",
        "FEATURE_DISPLAY_MISSING_RESOURCE_PAGE_CONTENT_KEYS",
        "FEATURE_EMAIL_MARKETING_FOOTER_BE",
        "FEATURE_EMAIL_MARKETING_FOOTER_FR",
        "FEATURE_EMAIL_MARKETING_POPUP_FR",
        "FEATURE_EMAIL_MARKETING_POPUP_NA",
        "FEATURE_EU_EXPANSION_FR",
        "FEATURE_EU_PRICE_FORMAT",
        "FEATURE_FASTER_CHECKOUT_SIGN_IN",
        "FEATURE_GOOGLE_ADDRESS_AUTOCOMPLETE",
        "FEATURE_INTERNATIONAL",
        "FEATURE_IP_BASED_REDIRECT",
        "FEATURE_MY_ACCOUNT_PAYMENT_FORM",
        "FEATURE_PDP",
        "FEATURE_PDP_REFACTOR",
        "FEATURE_PDP_CART_DELIVERY",
        "FEATURE_PERFORMANCE_SESSION_MEMBERSHIP",
        "FEATURE_IFRAME_PRINT",
        "FEATURE_RH_TD",
        "FEATURE_SAVE_CARD",
        "FEATURE_SEARCH_ALL_BRANDS",
        "FEATURE_CONCEPT_TABS",
        "FEATURE_SERVICE_WORKER",
        "FEATURE_SPLUNK_RUM",
        "FEATURE_SPLUNK_SESSION_RECORDER",
        "FEATURE_SSR",
        "FEATURE_SSR_WITH_CLIENT_RENDER",
        "FEATURE_SUPPRESS_EU_UPSELL_SALE",
        "FEATURE_SUPPRESS_UK",
        "FEATURE_SWATCH_AUTO_COMPLETE",
        "FEATURE_TAILWIND_ICON",
        "FEATURE_USE_PLACEHOLDER_IMAGES",
        "FEATURE_USE_QUANTITY",
        "FEATURE_USE_QUANTITY_IN_STOCK",
        "FEATURE_WEB_VITALS",
        "PAY_BY_BANK_FRAME_ORIGIN",
        "PAY_BY_BANK_SCRIPT",
        "FEATURE_ANALYTICS_FORM_DATALAYER",
        "REMOVE_ANALYTICS_LOADER",
        "FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE",
        "FEATURE_ALWAYS_SSR",
        "FEATURE_BYPASS_STAGGER_LOAD",
        "FEATURE_LOAD_PERFORMANCE",
        "FEATURE_INFINITE_SCROLL_REFACTOR",
        "FEATURE_TAILWIND_COMPONENTS",
        "FEATURE_TAILWIND_TEXTFIELD",
        "FEATURE_URL_CHANGE",
        "FEATURE_REMOVE_PG_TERM",
        "FEATURE_GLOBAL_COUNTRY_SELECTOR",
        "FEATURE_BCT_SUNSET",
        "FEATURE_CART_RUSH_SHIP",
        "FEATURE_PG_ENHANCEMENT",
        "FEATURE_PO_BOX_CHECK",
        "FEATURE_CART_SHIP_ADDRESS_DELETE",
        "FEATURE_ADDRESS_SAVED_VALIDATION",
        "FEATURE_INT_COOKIE_PREFERENCE",
        "FEATURE_RH_PROMO_BANNER",
        "FEATURE_ADDON_DROPDOWN_FIX",
        "FEATURE_PG_DEFAULT_ITEMS_PER_PAGE",
        "FEATURE_NEW_RH_IMAGE",
        "FEATURE_AVS_CHECK",
        "FEATURE_PDP_REDESIGN",
        "FEATURE_ROOMS_CAROUSEL_FIX",
        "FEATURE_CG_STYLE_UPDATE"
      ];

      const FEATURE_CART_NEW_UI = getEnvOrCookie("FEATURE_CART_NEW_UI");

      const REACT_APP_KEYCLOAK_CLIENTID =
        checkCountryIsEu({
          country: getCountryFromUrl()
        }) && env?.APP_ID == ESTORE_APP_ID
          ? env?.REACT_APP_KEYCLOAK_CLIENTID_UK
          : env?.REACT_APP_KEYCLOAK_CLIENTID;

      const args = Object.keys(env || {}) // Add a check for null or undefined
        .map(k => ({ [k]: parseVal(env?.[k]) })) // Use optional chaining for null/undefined safety
        .concat({
          ...mapKeysToEnvOrCookie(keys),
          FEATURE_CG_CAROUSEL: featureCgCarousel
            ? "true"
            : env?.FEATURE_CG_CAROUSEL,
          FEATURE_SSR_WITH_CLIENT_RENDER: forceCSR
            ? "false"
            : featureSSR !== undefined
            ? featureSSR
            : env?.FEATURE_SSR,
          FEATURE_SSR: featureSSR !== undefined ? featureSSR : env?.FEATURE_SSR,
          FEATURE_AEM_SSR_WITH_CLIENT_RENDER: forceCSR
            ? "false"
            : featureAemSSR !== undefined
            ? featureAemSSR
            : env?.FEATURE_AEM_SSR,
          FEATURE_AEM_SSR:
            featureAemSSR !== undefined ? featureAemSSR : env?.FEATURE_AEM_SSR,
          REACT_APP_BFF_ORIGIN: env?.REACT_APP_BFF_ORIGIN,
          FEATURE_AEM_RENDER_CONTROL: featureAemRenderControl
            ? "true"
            : env?.FEATURE_AEM_RENDER_CONTROL,
          FEATURE_USE_QUANTITY: featureUseQuantity
            ? "true"
            : env?.FEATURE_USE_QUANTITY,
          FEATURE_WISTIA_OPTIMIZATIONS: featureWistiaOptimizations
            ? "true"
            : env?.FEATURE_WISTIA_OPTIMIZATIONS,
          FEATURE_EMAIL_MARKETING_POPUP_NA: featureEmailMarketingPopupNA
            ? "true"
            : env?.FEATURE_EMAIL_MARKETING_POPUP_NA,
          FEATURE_EMAIL_MARKETING_FOOTER_NA: featureEmailMarketingFooterNA
            ? "true"
            : env?.FEATURE_EMAIL_MARKETING_FOOTER_NA,
          FEATURE_SCHEDULING_VIRTUAL_AGENT: featureSchedulingVirtualAgent
            ? "true"
            : env?.FEATURE_SCHEDULING_VIRTUAL_AGENT,
          FEATURE_EMAIL_MARKETING_FOOTER_UK: featureEmailMarketingFooterUK
            ? "true"
            : env?.FEATURE_EMAIL_MARKETING_FOOTER_UK,
          FEATURE_EU_PRICE_FORMAT: featureEuPriceFormat
            ? "true"
            : env?.FEATURE_EU_PRICE_FORMAT,
          FEATURE_IFRAME_PRINT: featureIFramePrint
            ? "true"
            : env?.FEATURE_IFRAME_PRINT,
          FEATURE_TAILWIND_ICON: featureTailwindIcon
            ? "true"
            : env?.FEATURE_TAILWIND_ICON,
          FEATURE_POSTALCODE_DIALOG_V2: featurePostalCodeDailogV2
            ? "true"
            : env?.FEATURE_POSTALCODE_DIALOG_V2,
          FEATURE_CHECKOUT_SHIPPING_BUTTON_V2: featureShippingButtonV2
            ? "true"
            : env?.FEATURE_CHECKOUT_SHIPPING_BUTTON_V2,
          FEATURE_STYTCH: featureStytch ? "true" : env?.FEATURE_STYTCH,
          FEATURE_ATC_DISABLE_FIX: featureAtcDisableFix
            ? "true"
            : env?.FEATURE_ATC_DISABLE_FIX,
          FEATURE_RH_PROMO_BANNER: featureRHPromoBanner
            ? "true"
            : env?.FEATURE_RH_PROMO_BANNER,
          REACT_APP_KEYCLOAK_CLIENTID,
          FEATURE_CART_NEW_UI,
          FEATURE_CART_PAYMENT_V1:
            FEATURE_CART_NEW_UI !== undefined
              ? false
              : Boolean(getEnvOrCookie("FEATURE_CART_PAYMENT_V1")),
          FEATURE_CART_BILLING_ADDRESS_V1:
            FEATURE_CART_NEW_UI !== undefined
              ? false
              : Boolean(getEnvOrCookie("FEATURE_CART_BILLING_ADDRESS_V1")),
          FEATURE_CART_3DS_DIALOG: getPreferredFlag(
            "FEATURE_CART_3DS_DIALOG",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_BACKGROUND: getPreferredFlag(
            "FEATURE_CART_BACKGROUND",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_BREADCRUMBS: getPreferredFlag(
            "FEATURE_CART_BREADCRUMBS",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_CTA: getPreferredFlag(
            "FEATURE_CART_CTA",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_EMAIL: getPreferredFlag(
            "FEATURE_CART_EMAIL",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_LOGO: getPreferredFlag(
            "FEATURE_CART_LOGO",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_PREVIEW: getPreferredFlag(
            "FEATURE_CART_PREVIEW",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_QTY: getPreferredFlag(
            "FEATURE_CART_QTY",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_SIGN_IN: getPreferredFlag(
            "FEATURE_CART_SIGN_IN",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_CART_TITLE: getPreferredFlag(
            "FEATURE_CART_TITLE",
            "FEATURE_CART_NEW_UI"
          ),
          FEATURE_PAY_BY_BANK: getPreferredFlag(
            "FEATURE_PAY_BY_BANK",
            "FEATURE_CART_NEW_UI"
          )
        });

      let _env = Object.assign({}, ...args);

      /** custom event implementation requires because country change doesn't trigger page reload */
      if (typeof window !== "undefined") {
        window?.addEventListener("country", (cevent: CustomEvent) => {
          const REACT_APP_KEYCLOAK_CLIENTID =
            checkCountryIsEu({
              country: cevent?.detail?.country
            }) && env?.APP_ID == ESTORE_APP_ID
              ? env?.REACT_APP_KEYCLOAK_CLIENTID_UK
              : env?.REACT_APP_KEYCLOAK_CLIENTID;

          _env = Object.assign(_env, { REACT_APP_KEYCLOAK_CLIENTID });
        });
      }

      return _env;
    }
  }
};

export const cachedGlobals: CachedGlobals = {};

export const getGlobal = (
  name: keyof GlobalGetters,
  loc?: string
): CustomGlobals => {
  // globalGetters will only be ran once on client and once on server
  return loc || typeof cachedGlobals[name] === "undefined"
    ? (cachedGlobals[name] = globalGetters[name](loc))
    : cachedGlobals[name];
};

/** copy pasted the code from getCountryFromUrl because import the utility throwing an error */
export const getCountryFromUrl = (): string => {
  const req = getReqContext();

  let urlCountry = "";
  if (processEnvServer) {
    urlCountry = req?.url?.split("/")[1];
  } else {
    let url;
    if (typeof window !== "undefined") {
      url = new URL(window.location.href);
    }
    urlCountry = url ? url.pathname.split("/")[1] : "us";
  }

  let countrySelection: string = "US";
  if (
    ["/ca/", "/us/", "/gb/", "/de/", "/be/", "/es/", "/fr/"]?.includes(
      `/${urlCountry}/`
    )
  ) {
    countrySelection = urlCountry?.toUpperCase();
  }
  return countrySelection;
};
