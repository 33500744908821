import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";

const env = useEnv();
const FEATURE_PG_DEFAULT_ITEMS_PER_PAGE = yn(
  env.FEATURE_PG_DEFAULT_ITEMS_PER_PAGE
);

export const getPGDefaultItemsPerPage = () => {
  return FEATURE_PG_DEFAULT_ITEMS_PER_PAGE ? 72 : 48;
};
