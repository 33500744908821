import { StyleRules } from "@mui/styles";
import { ifSites1492 } from "./toggles";

export type StylesDecoratorFactory<Options, Styles extends StyleRules = {}> = (
  options: Options
) => (styles: Styles) => Styles;

export type StylesDecorator<Styles extends StyleRules = {}> = (
  styles: Styles
) => Styles;

export function composeStyles<Styles extends StyleRules>(
  initialStyles: Styles,
  ...decorators: StylesDecorator<Styles>[]
): Styles {
  return decorators.reduce(
    (styles, decorate) => decorate(styles),
    initialStyles
  );
}

export const withZoomOnHover: StylesDecorator = styles => {
  return {
    ...styles,

    transition: "all 500ms ease",

    "&:hover": {
      ...styles["&:hover"],

      transform: "scale(1.085)"
    }
  };
};

export const withImageStyles: StylesDecoratorFactory<{
  zoomOnHover: boolean;
}> =
  ({ zoomOnHover }) =>
  initialStyles =>
    composeStyles(initialStyles, styles =>
      ifSites1492.isOn && zoomOnHover ? withZoomOnHover(styles) : styles
    );
