import yn from "yn";
import { PAGINATION_DEFAULT_COUNT_OF_CG } from "./constants";
import { useEnv } from "hooks/useEnv";
import { getPGDefaultItemsPerPage } from "./getPGDefaultItemsPerPage";

const env = useEnv();

const isCGPaginationFeatureEnabled = yn(env.FEATURE_CG_PAGINATION);

const PG_DEFAULT_ITEMS_PER_PAGE = getPGDefaultItemsPerPage();

export const showPGPaginationModule = (
  isNextPageLoading: boolean,
  totalNumRecs: number
) => {
  if (!isNextPageLoading && totalNumRecs > PG_DEFAULT_ITEMS_PER_PAGE) {
    return true;
  } else {
    return false;
  }
};

export const showCGPaginationModule = (
  isNextPageLoading: boolean,
  totalNumRecs: number
) => {
  if (
    !isNextPageLoading &&
    isCGPaginationFeatureEnabled &&
    totalNumRecs > PAGINATION_DEFAULT_COUNT_OF_CG
  ) {
    return true;
  } else {
    return false;
  }
};
