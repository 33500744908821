import { Breakpoint } from "@mui/material";
import {
  enabledEUCountries,
  europeanCountries
} from "@RHCommerceDev/resources/countries-config.json";
import { ProductImagePresetKeys } from "./getImageUrlWithPreset";
export const DEFAULT_CURRENCY_CODE = "USA";

export const TYPEAHEAD_START = 3;
export const TYPEAHEAD_DEBOUNCE_KEY = "typeahead";

export const DEFAULT_DEBOUNCE_TIME = 500; // in ms
export const COUNTER_ONE = 1;
export const COUNTER_TWO = 2;
export const DEFAULT_RECS_PER_PAGE = 24;
export const ZERO_RESULTS = 0;
export const GIFT_MESSAGE_MAX_LENGTH = 50;
export const SHIPLABEL_SIDEMARK_MAX_LENGTH = 30;
export const PROFILE_STATUS_ANONYMOUS = "anonymous";
export const PROFILE_STATUS_AUTHENTICATED = "authenticated";
export const PROFILE_STATUS_RECOGNIZED = "recognized";
export const INTRODUCING = "NEW";
export const FROM = "From";
export const FOR = "for";
export const Configured_PRICE = "Configured price";
export const MEMBER_PRICE_TAG = "Member";
export const CONTRACT_PRICE_TAG = "Contract";
export const TRADE_PRICE_TAG = "Trade";
export const REGULAR_PRICE_TAG = "Regular";
export const SALE_PRICE_TAG = "Sale";
export const FINAL_SALE_PRICE_TAG = "Final Sale";
export const SELECT_ITEMS_ON_SALE_STARTING_AT_TAG =
  "Select Items On Sale Starting at";
export const PREVIEW_THE_COLLECTION = "Preview the collection";
export const ESTORE_APP_ID = "ESTORE_UI";
export const USER_TYPE = {
  CONTRACT: "CONTRACT",
  TRADE: "TRADE"
};

export const CREATE_ACCOUNT_COUNTRY_CODE = {
  US: "create_account_us",
  FR: "create_account_fr",
  ES: "create_account_es",
  DE: "create_account_de",
  BE: "create_account_be",
  GB: "_create_account_gb",
  CA: "create_account_ca"
};

export const Reset_password = {
  US: "reset_password_us",
  FR: "reset_password_fr",
  ES: "reset_password_es",
  DE: "reset_password_de",
  BE: "reset_password_be",
  GB: "reset_password_gb",
  CA: "reset_password_ca"
};

export const Sign_in_with_a_secure_link = {
  US: "sigin_in_with_secure_link",
  FR: "sign_in_with_secure_link_fr",
  ES: "sign_in_with_secure_link_es",
  DE: "sign_in_with_secure_link_de",
  BE: "sign_in_with_secure_link_be",
  GB: "sign_in_with_secure_link_gb",
  CA: "sign_in_with_magic_link_ca"
};

export const unable_to_auth_magic_link = "unable_to_auth_magic_link";

// font weights use Common Weight Name Mapping found at
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
export const FONT_THIN = 100;
export const FONT_REGULAR = 400;
export const FONT_MEDIUM = 500;
export const FONT_BOLD = 600;
export const FONT_LIGHT = 300;
export const FONT_BARON_SANS = "RHSans, Helvetica, Arial";
export const FONT_BARON_SANS_LIGHT = "RHSans-Light, Helvetica, Arial";
export const FONT_BARON_SANS_EXTRA_LIGHT =
  "RHSans-ExtraLight, Helvetica, Arial";
export const FONT_BARON_SANS_THIN = "RHSans-Thin, Helvetica, Arial";
export const FONT_BARON_SANS_ULTRA_THIN = "RHSans-UltraThin, Helvetica, Arial";
export const FONT_BARON_SANS_ROMAN = "RHSans-Roman, Helvetica, Arial";
export const FONT_BARON_SANS_HAIRLINE = "RHSans-Hairline, Helvetica, Arial";
export const FONT_CASLON = "CaslonRHThin, Big Caslon, Palatino";
export const FONT_ACUMIN_PRO = "acumin-pro, Arial, Helvetica, sans-serif";
export const FONT_CASLON_SUPERFINE = "CaslonRH-Superfine, Helvetica, Arial";
export const FONT_RHC_ULTRA_HAIRLINE = "RHC-UltraHairline, Helvetica, Arial";
export const FONT_RHC_HAIRLINE = "RHC-Hairline, Helvetica, Arial";
export const FONT_RHC_ULTRA_THIN = "RHC-UltraThin, Helvetica, Arial";
export const FONT_RHC_THIN = "RHC-Thin, Helvetica, Arial";
export const FONT_BARON_SANS_BASICS = "RHSans";
export const FONT_RHC_ROMAN = "RHC-Roman, Helvetica, Arial";
export const FONT_BARON_SANS_THIN_BASICS = "RHSans-Thin";
export const FONT_BARON_SANS_LIGHT_BASICS = "RHSans-Light";

export const US = "US";
export const CA = "CA";
export const NA_COUNTRIES = [CA, US];
//this is required to avoid circular dependency
export const UK = enabledEUCountries["UK"];
export const DE = enabledEUCountries["DE"];
export const BE = enabledEUCountries["BE"];
export const ES = enabledEUCountries["ES"];
export const FR = enabledEUCountries["FR"];
export const EU_COUNTRIES = Object.values(enabledEUCountries);

export const PRODUCT_IMAGE_PRESETS: {
  [key in ProductImagePresetKeys]: string;
} = {
  mobileThumb: "$IS-PDP-mthumb$", // preset width: 275, quality: 85%
  mobileFull: "$IS-PDP-mfull$", // preset width: 296, quality: 85%
  mobileZoom: "$IS-PDP-mainzoom$", // preset width: 2000, quality: 100%
  "rhir-mobileZoom": "$PDP-IR-2000-Zoom$", // preset width: 2000
  containerZoom: "$NP-Con_mainzoom$",
  "rhir-containerZoom": "$PDP-IR-2000-Zoom$", // preset width: 2000
  cgBanner: "$np-common-lg$",
  swatchLarge: "$np-common-lg$",
  "pg-card-xs": "$np-common-sm$",
  "pg-card-md": "$np-fullwidth-sm$",
  "pdp-hero-xs": "$PDP-IS-768$", // preset width: 768, quality: 80%
  "pdp-hero-sm": "$PDP-IS-992$", // preset width: 992, quality: 80%
  "pdp-hero-md": "$PDP-IS-1200$", // preset width: 1220, quality: 80%
  "pdp-hero-lg": "$PDP-IS-1600$", // preset width: 1600, quality: 80%
  "pdp-hero-xl": "$PDP-IS-2000$", // preset width: 2000, quality: 80%
  "rhir-pdp-hero-xs": "$PDP-IR-768$", // preset width: 768
  "rhir-pdp-hero-sm": "$PDP-IR-992$", // preset width: 992
  "rhir-pdp-hero-md": "$PDP-IR-1200$", // preset width: 1220
  "rhir-pdp-hero-lg": "$PDP-IR-1600$", // preset width: 1600
  "rhir-pdp-hero-xl": "$PDP-IR-2000$", // preset width: 2000

  "PDPv2-hero-xs": "$PDPv2-IS-768$", // preset width: 768px, quality:
  "PDPv2-hero-sm": "$PDPv2-IS-992$", // preset width: 992px, quality:
  "PDPv2-hero-md": "$PDPv2-IS-1200$", // preset width: 1200px, quality:
  "PDPv2-hero-lg": "$PDPv2-IS-1600$", // preset width: 1600px, quality:
  "PDPv2-hero-xl": "$PDPv2-IS-2000$", // preset width: 2000px, quality:

  "rhir-PDPv2-hero-xs": "$PDPv2-IR-768$", // preset width: 768px,
  "rhir-PDPv2-hero-sm": "$PDPv2-IR-992$", // preset width: 992px,
  "rhir-PDPv2-hero-md": "$PDPv2-IR-1200$", // preset width: 1200px,
  "rhir-PDPv2-hero-lg": "$PDPv2-IR-1600$", // preset width: 1600px,
  "rhir-PDPv2-hero-xl": "$PDPv2-IR-2000$", // preset width: 2000px,

  "full-width-xs": "$np-fullwidth-xs$",
  "full-width-sm": "$np-fullwidth-sm$",
  "full-width-md": "$np-fullwidth-md$",
  "full-width-lg": "$np-fullwidth-lg$",
  "full-width-xl": "$np-fullwidth-xl$",
  "rhrpdp-hero-colorizable-xs": "$np-fullwidth-IR-xs$",
  "rhrpdp-hero-colorizable-sm": "$np-fullwidth-IR-sm$",
  "rhrpdp-hero-colorizable-md": "$np-fullwidth-IR-md$",
  "rhrpdp-hero-colorizable-lg": "$np-fullwidth-IR-lg$",
  "rhrpdp-hero-colorizable-xl": "$np-fullwidth-IR-xl$",
  "rhrpdp-zoom-colorizable-xs": "$highres-zoom-1K-IR-WRH$",
  "rhrpdp-zoom-colorizable-sm": "$highres-zoom-2K-IR-WRH$",
  "rhrpdp-zoom-colorizable-md": "$highres-zoom-2K-IR-WRH$",
  "rhrpdp-zoom-colorizable-lg": "$highres-zoom-3K-IR-WRH$",
  "rhrpdp-zoom-colorizable-xl": "$highres-zoom-3K-IR-WRH$",
  "rhrpdp-zoom-noncolorizable-xs": "$highres-zoom-1K-WRH$",
  "rhrpdp-zoom-noncolorizable-sm": "$highres-zoom-2K-WRH$",
  "rhrpdp-zoom-noncolorizable-md": "$highres-zoom-2K-WRH$",
  "rhrpdp-zoom-noncolorizable-lg": "$highres-zoom-3K-WRH$",
  "rhrpdp-zoom-noncolorizable-xl": "$highres-zoom-3K-WRH$",

  "cg-banner-xs": "$np-cgfull-xs$", // preset width: 768, quality: 95%
  "cg-banner-sm": "$np-cgfull-sm$", // preset width: 992, quality: 95%
  "cg-banner-md": "$np-cgfull-md$", // preset width: 1220, quality: 95%
  "cg-banner-lg": "$np-cgfull-lg$", // preset width: 1600, quality: 95%
  "cg-banner-xl": "$cg-common-15k$",
  "cg-banner-full-xs": "$np-cgfull-xs$", // preset width: 768, quality: 95%
  "cg-banner-full-sm": "$np-cgfull-sm$", // preset width: 992, quality: 95%
  "cg-banner-full-md": "$np-cgfull-md$", // preset width: 1220, quality: 95%
  "cg-banner-full-lg": "$np-cgfull-lg$", // preset width: 1600, quality: 95%
  "cg-banner-full-xl": "$cg-common-15k$",
  "swatch-order-small-xs": "$np-thumbnail-xs$", // preset width: 67, quality: 80%
  "swatch-order-small-sm": "$np-thumbnail-sm$", // preset width: 140, quality: 80%
  "swatch-order-small-md": "$np-thumbnail-md$", // preset width: 181, quality: 80%
  "swatch-order-small-lg": "$np-thumbnail-lg$", // preset width: 218, quality: 80%
  "swatch-order-small-xl": "$np-thumbnail-xl$", // preset width: 291, quality: 80%
  // TODO remove these if they're not being used
  "swatch-order-large-xs": "$np-thumbnail-xs$",
  "swatch-order-large-sm": "$np-thumbnail-sm$",
  "swatch-order-large-md": "$np-thumbnail-md$",
  "swatch-order-large-lg": "$np-thumbnail-lg$",
  "swatch-order-large-xl": "$np-thumbnail-xl$",
  "swatch-large-xs": "$np-common-xs$",
  "swatch-large-sm": "$np-common-sm$",
  "swatch-large-md": "$np-common-md$",
  "swatch-large-lg": "$np-common-lg$",
  "swatch-large-xl": "$np-common-xl$",
  "monogram-large-xs": "$swatch-large-xs$",
  "monogram-large-sm": "$swatch-large-sm$",
  "monogram-large-md": "$swatch-large-md$",
  "monogram-large-lg": "$swatch-large-lg$",
  "monogram-large-xl": "$swatch-large-xl$",
  relatedPCard: "$IS-PDP-CARO$",
  "rhir-relatedPCard": "$IS-PDP-CARO$",
  cartLineItemCard: "$IS-PDP-CARO$",
  "rhir-cartLineItemCard": "$IS-PDP-CARO$",
  "rhir-mobileThumb": "$IS-PDP-CARO$",
  addToProjectCard: "$CON_IS_162$",
  "PDP-MX-1376": "$PDP-MX-1376$"
};

export type ImageAspectRatioKeys =
  | "collectionBanner"
  | "collectionBannerFull"
  | "productCard"
  | "heroImage"
  | "thumbnail"
  | "swatch"
  | "swatchLPBanner"
  | "verticalProductTile"
  | "horizontalProductTile";

export const IMAGE_ASPECT_RATIO: {
  [key in ImageAspectRatioKeys]: number;
} = {
  collectionBanner: 343 / 168.53,
  collectionBannerFull: 718 / 283,
  productCard: 426.66 / 416.11,
  heroImage: 320 / 311.91,
  thumbnail: 275 / 269,
  swatch: 1,
  swatchLPBanner: 2.64,
  verticalProductTile: 416 / 405,
  horizontalProductTile: 416 / 152
};

export const COLLECTION_GALLERY_DEFAULT_COUNT = 15;
export const PRODUCT_GALLERY_DEFAULT_COUNT = 24;
export const ORDER_DETAILS_DEFAULT_COUNT = 10;
export const PAGINATION_DEFAULT_COUNT_OF_CG = 24;

// Static promo context names
export const PROMO_ABOVE_NAV = "AboveNav";
export const PROMO_HOME_PAGE = "HomePage";

export const BC_PRIMARY_COLOR = "#6c6663";
export const BC_SECONDARY_COLOR = "#9c9c9c";

export const LIGHT_GRAY = "#FAFAFA";

export const MO_PRIMARY_COLOR = "#000";
export const MO_SECONDARY_COLOR = "#999";

export const REIMAGINE_RH_LIGHT = "#F1F0ED";

export const RH_MEDIUM = "#D4D2CF";
export const RH_DARK = "#898886";
export const RH_BLACK = "#000";
export const RH_WHITE = "#FFF";
export const RH_BLUE_700 = "#015FCC";
export const RH_GRAY_800 = "#333333";
export const RH_GRAY_100 = "#F1F0ED";
export const RH_GRAY_600 = "#666666";
export const RH_GRAY_900 = "#201F1F";
export const RH_RED_300 = "#CA6667";
export const RH_WHITE_200 = "#f1f1f1";
export const RH_GREY_200 = "#E6E6E6";
export const RH_WHITE_300 = "#F9F7F4";
export const RH_WHITE_400 = "#F8F8F8";
export const RH_GRAY_700 = "#808080";
export const RH_WHITE_500 = "#ffffff33";
export const RH_BLACK_200 = "#00000099";

export const ADDITIONAL_FREE_SHIPPING_BANNER = "FREE SHIPPING ON SELECT ITEMS";

export const IN_STOCK_READY_TO_SHIP = "IN STOCK & READY TO SHIP";

// Breakpoints by the numbers!
export const BREAKPOINT_XS = 0; // Use 375 as reference for XS screens
export const BREAKPOINT_SM = 768;
export const BREAKPOINT_IPAD = 820;
export const BREAKPOINT_MD = 992;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 1600;
export const BREAKPOINT_SERVER_MOBILE = 0;
export const BREAKPOINT_SERVER_DESKTOP = 1200;
export const BREAKPOINT_660 = 660;

export const MONOGRAM_ORDER_DEFAULT = {
  lines: [],
  colorId: "",
  borderId: "",
  fontId: "",
  fontCode: "",
  fontColorCode: "",
  borderCode: "",
  fulfillmentCode: "",
  maxLines: 0,
  styleId: "",
  description: ""
};

export enum AddressChoice {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED"
}

export const ADDRESS_LINE_2_MAX_LENGTH = 55;
export const ADDRESS_LINE_1_ARIA_CONTROL =
  "places-autocomplete-generic__popper";

export const GRID_SIZES: {
  [key in Breakpoint]: {
    col: number;
    spacing: number;
  };
} = {
  xs: { col: 2, spacing: 4.5 },
  sm: { col: 4, spacing: 11.5 },
  md: { col: 4, spacing: 11.5 },
  lg: { col: 4, spacing: 17 },
  xl: { col: 4, spacing: 20 }
};

export const CT_CONTLOOP_MIN_WIDTH = 24;
export const CT_CONTLOOP_MAX_WIDTH = 96;
export const CT_CORDLOCK_MIN_WIDTH = 24;

// ROMAN SHADE
export const ROMAN_CORDLOCK_MAX_WIDTH = 90;
export const ROMAN_CORDLOCK_MAX_LENTH = 96;
export const ROMAN_CONTLOOP_MIN_WIDTH = 13;
export const ROMAN_MOTORIZED_MIN_WIDTH = 24.5;
export const ROMAN_MOTORIZED_MAX_WIDTH = 144;
export const ROMAN_MOTORIZED_MIN_LENGTH = 10;
export const ROMAN_MOTORIZED_MAX_LENGTH = 144;

// ROLLER SHADE
export const ROLLER_MOTORIZED_MIN_WIDTH = 22.25;
export const ROLLER_MOTORIZED_MIN_LENGTH = 6;
export const ROLLER_MOTORIZED_MAX_WIDTH = 144;
export const ROLLER_MOTORIZED_MAX_LENGTH = 144;

// WOODEN BLIND SHADE
export const BLIND_MOTORIZED_MIN_WIDTH = 18;
export const BLIND_MOTORIZED_MIN_LENGTH = 10;
export const BLIND_MOTORIZED_MAX_WIDTH = 72;
export const BLIND_MOTORIZED_MAX_LENGTH = 72;

export const CONTROL_MOTORIZED = "Motorized";
export const CONTROL_CONTINUOUS_LOOP = "Continuous Loop";
export const CONTROL_STANDARD = "Standard Cord Lock";
export const DEFAULT_TILT_POSITION_MOTORIZED = "100056";
export const SHADE_TYPE_MAPPER = {
  RS: "roman",
  RO: "roller",
  WD: "wood"
};

export const VIEW_TYPE_SMALL = "small";
export const VIEW_TYPE_MEDIUM = "medium";
export const VIEW_TYPE_LARGE = "large";
export const IS_USER_SAVE_COOKIE_PREFERENCES =
  "is_user_save_cookie_preferences";
export const USER_COOKIE_PREFERENCES = "user_cookie_preferences";
export const USER_ACCEPTED_ALL_COOKIES = "user_accepted_all_cookies";
export const ACCEPT_ALL = "ACCEPT ALL";
export const DENY_ALL = "DENY ALL";
export const SAVE_MY_PREFERENCES = "SAVE MY PREFERENCES";
export const ACCEPT = "Accept";
export const MANAGE = "MANAGE COOKIES";
export const COUNTRY_COOKIE = "country_cookie";
export const MEASUREMENT_COOKIE = "measurement_cookie";
export const LANGUAGE_COOKIE = "language_cookie";
export const POSTAL_CODE_COOKIE = "pc";
export const COUNTRY = "SHIP TO";
export const SHIP_TO_THE_COUNTRY = "SHIP TO THE COUNTRY";
export const LANGUAGE = "LANGUAGE";
export const MEASUREMENT = "MEASUREMENT";
export const DEFAULT_UPSELL_COMPONENT_TITLE = "YOU MIGHT ALSO LIKE";
export const APPLY = "APPLY";
export const RH_ALERT_COOKIE_NAME = "RH_ALERT";
export const ALERT_COOKIE_VALUE = "RH_ALERT";
export const ALERT_COOKIE_DEFAULT_HOUR = 6;
export const RHUK_HOST = "RHUK";
export const RH_HOST = "RH";
export const GBP = "GBP";
export const EUR = "EUR";
export const LOCAL_STORAGE_EVENT = "storageEvent";
export const DONE = "DONE";
export const SAVE = "SAVE";
export const SHIP_TO = "SHIP TO";
export const AVAILABLE_SHIPPING_ONE_COUNTRY =
  "ITEM ONLY AVAILABLE FOR SHIPPING IN UNITED STATES";
export const RH_CUSTOMER_NUMBER = "800.762.1005";
export const RHUK_CUSTOMER_NUMBER = "+44.800.279.3707";
export const RHDE_CUSTOMER_NUMBER = "+49 211 5401389200";
export const RHBE_CUSTOMER_NUMBER = "+32 800 38291";
export const RHES_CUSTOMER_NUMBER = "+34 800 098 001";
export const CUSTOMER_NUMBER_FORMATE = "+XX XXXX XXXXXX";

export const WORH_BOTTOM_LINKS_CATEGORY_ID = "cat25260015";
export const WORH_MOBILE_FOOTER_CATEGORY_ID = "cat25070057";
export const CATALOG_NAV_CONCEPTS_ID = "cat25640008";
export const CATALOG_NAV_CONCEPTS_ALTERNATIVE_ID = "cat25630004";
export const CATALOG_NAV_CONCEPTS_LABEL = "CONCEPT";
export const CATALOG_NAV_BABY_AND_CHILD_ID = "cat26600024";
export const CATALOG_NAV_TEEN_ID = "cat26600023";

export const WORH_LINKS_CATEGORY_ID_UK = "cat27520003";
export const WORH_LINKS_CATEGORY_ID_NA = "cat25070049";

// to avoid text breakings and line breakings with links
/**
 * thin space \&thinsp;
 */
export const THINSPACE = String.fromCharCode(8201);
/**
 * non-breakable space \&nbsp;
 */
export const NBSP = String.fromCharCode(160);

export const RHR_DRAWER_TRANSITION_DURATION = 500;
export const RHR_ACCORDION_TRANSITION_DURATION = 500;
export const RHR_FADE_TIMEOUT_DURATION = 500;
export const RHR_FADE_TIMEOUT_DURATION_MIN = 10;

export const RHR_HIGHLIGHT_TRANSITION_DURATION = 500;
export const PAGE_BG_GREY = "#f9f7f4";
export const SKELETON_BG_WHITE = "#F5F5F5";
export const PAGE_BG_WHITE = "#FFFFFF";
export const SKELETON_BG_GREY = "#F1EFEC";
export const SKELETON_ANIMATION = "pulse";
export const RHR_BACKDROP_OVERLAY = "#00000026";
export const PAGE_BG_TRANSPARENT = "transparent";

export const DEFAULT_GRID_COLUMNS = 6;
export const DEFAULT_VIEW = "vertical";
export const ONLY_AVAILABLE =
  "This Item is currently unavailable for shipping to {region}";
export const CHECKOUT_WITH_AVAILABLE_ITEMS = "CHECKOUT WITH AVAILABLE ITEMS";
export const CHECKOUT_WITH_AVAILABLE_ITEM = "CHECKOUT WITH AVAILABLE ITEM";
export const CHECKOUT = "CHECKOUT";
export const ITEMS_ARE_UNAVAILABLE = "{count} ITEMS ARE UNAVAILABLE";
export const ITEM_IS_UNAVAILABLE = "{count} ITEM IS UNAVAILABLE";
export const ITEMS_AVAILABLE = "{count} ITEMS AVAILABLE";
export const ITEM_AVAILABLE = "{count} ITEM AVAILABLE";
export const PG_SEARCH_TERM = "pgterm";

export const JAVASCRIPT_ENABLED = "true";
export const JAVA_ENABLED = "true";
export const COLOR_DEPTH = "32";

export const BRAND_NAME_TO_BRAND_CODE: { [key: string]: string } = {
  RH: "RH",
  "RH MODERN": "MO",
  "RH BABY & CHILD": "BC",
  "RH TEEN": "TN",
  "SKI HOUSE": "SH",
  "RH SKI HOUSE": "SH",
  "RH BEACH HOUSE": "BH",
  OUTDOOR: "OD",
  "RH OUTDOOR": "OD",
  INTERIORS: "INT",
  "RH INTERIORS": "INT",
  "RH CONTEMPORARY": "CN",
  CONTEMPORARY: "CN",
  "BABY & CHILD": "BC",
  TEEN: "TN"
};

export const DEFAULT_SELECTED_BRAND = "RH";
export const QUERY_PARAM_SITE = "&site=";

export enum PDP_LAYOUT {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
  SQUARE = "SQUARE"
}

export const ADD_TO_ORDER = "ADD TO ORDER";
export const SAVE_FOR_LATER = "SAVE FOR LATER";

export const IN_STOCK = "IN STOCK";

export const CUSTOMIZE = "CUSTOMIZE";
export const DIMENTIONS_DETAILS = "DIMENSIONS & DETAILS";
export const PINCH_ZOOM = "Pinch to Zoom";
export const STARTING_AT = "STARTING AT ";
export const SEE_ALL_OPTIONS_TOOLTIP = "Select to see available options";
export const UPDATE_OTHER_OPTIONS_TOOLTIP =
  "Update Other Options based on this selection";
export const IMAGE_UNAVAILABLE = "IMAGE UNAVAILABLE";
export const READY_TO_DELIVER_FOR = "READY TO DELIVER FOR";

export const catalogLinksToEnable = [
  "/catalog/sale/index.jsp",
  "/my-account/wish-list.jsp",
  "/content/category.jsp?context=BC_Registry",
  "/content/category.jsp?context=InteriorDesignServices",
  "/gift-registry"
];

export const PRIVACY_URL = "/our-company/privacy-policy";
export const COOKIES_URL = "/our-company/cookies";

export const COMPLETE_YOUR_ROOM = "COMPLETE YOUR ROOM";
export const COMPONENTS_STARTING_AT = "COMPONENTS STARTING AT";
export const VIEW_DETAILS = "VIEW DETAILS";
export const SHOP_ITEMS_IN_THIS_ROOM = "SHOP ITEMS IN THIS ROOM";
export const SHOP_ROOM = "SHOP ROOM";
export const POUNDS_OFF = "Pounds Off";
export const DOLLAR_OFF = "Dollar Off";

/** Standard date format for moment */
export const DATE_FORMAT = "MMMM D, yyyy";
export const UK_DATE_FORMAT = "DD MMMM YYYY";
export const US_DATE_FORMAT = "MM.DD.YYYY";
export const DATETIME_FORMAT = "MMMM D, yyyy hh:mm A";
export const DESIGN_CONSULTATION_DATE_FORMAT = "YYYY-MM-DD";
export const CATALOG_SALE_NAME = "sale";
export const CATALOG_SALE_COLOR = "#CA2022";

export const MEMBERS_SAVE = "MEMBERS SAVE";
export const UP_TO = "UP TO";
export const OFF_REGULAR_PRICE = "% OFF REGULAR PRICE";

export const SELECT_ITEMS_ON_SALE = "SELECT ITEMS ON SALE";

export const omitLinksForUK = [
  "RH GIFT CARD",
  "GIFT REGISTRY",
  "RH CREDIT CARD",
  "RH GIFT CARD BALANCE INQUIRY",
  "RH IN CANADA"
];

export const MICRO_SITES = ["SH", "BH", "OD", "IN", "CN"];
export const NORTH_AMERICA_CURRENCY_CODES = ["USD", "CAD", "USA"];

export const COOKIE_MAX_AGE_90_DAYS = 60 * 60 * 24 * 90;
export const COOKIE_MAX_AGE_34_DAYS = 60 * 60 * 24 * 34;
export const COOKIE_MAX_AGE_14_DAYS = 60 * 60 * 24 * 14;

export const REQUIRED_PERMISSION_COUNTRIES = [...europeanCountries, UK];

export const COUNTRIES = {
  US: "US",
  CA: "CA",
  GB: "GB"
};

export const X_SELECTED_COUNTRY = "x-selected-country";
export const RH_MEMBERS_PROGRAM = " RH MEMBERS PROGRAM.";
export const SAVE_25_ON_EVERYTHING = "SAVE 25% ON EVERYTHING*";

/**
 * PG image container max dimension
 */

export const INITIAL_PG_IMAGE_CONTAINER_DIMENSION = [453, 453];

export const PG_IMAGE_CONTAINER_DIMENSION = {
  "12": {
    xs: [343, 343],
    sm: [343, 343]
  },
  "6": {
    xs: [164, 164],
    sm: [336, 336],
    md: [436, 436],
    lg: [540, 540],
    xl: [700, 700]
  },
  "4": {
    xs: [164, 164],
    sm: [213, 213],
    md: [277, 277],
    lg: [347, 347],
    xl: [453, 453]
  }
};

export const ITEMS_PER_PAGE_PREFERENCE = "items-per-page-preference";

export const CG_ITEMS_PER_PAGE_PREFERENCE = "cg-items-per-page-preference";

export const MAX_QUANTITY = 40;

export const MY_ORDER_IMAGE_SIZE = 8;

export const EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_DETAILS = [
  "fabric",
  "color",
  "leather",
  "countertop",
  "hardware",
  "frame",
  "hair",
  "hair-on-hide",
  "finish"
];

export const EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_DETAILS = [
  "fabric",
  "color",
  "leather",
  "countertop",
  "hardware",
  "frame",
  "hair",
  "hair-on-hide",
  "color::fabric",
  "color::leather"
];

export const EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_UNAVAILABLE_HIDE = [
  "fabric",
  "color",
  "leather",
  "color::fabric",
  "color::leather"
];

export const EXCLUDED_FACET_LIST = ["category", "brand"];

export const HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS = ["frame"];

export const INTL_COUNTRY_SEQUENCE = ["US", "BE", "CA", "FR", "DE", "ES", "GB"];

export const COUNTRY_CODE_PATH = "us|gb|ca|de|be|es";

export const COOKIE_WITH_SESSION = "EMAIL_POP_UP_DATA";
export const PAGE_TITLE_ORDER_HISTORY = "Order History";
export const SELECTED_GALLERY_COOKIE = "selected_gallery";
export const SALE_PERCENT_100 = 100;
export const PAYMENT_CARD_TYPES = {
  CC_BTN: "CC_BTN",
  PBB: "PBB"
};

export const PAYMENT_TYPE_GIFT_CARD = "RH Gift Card";
export const PAYMENT_TYPE_RH_CREDIT_CARD = "RH Credit Card";
export const PAYMENT_TYPE_PAY_BY_BANK = "PayByBank";
export const PAYMENT_TYPE_PORTAL_PAYMENT = "PortalPayment";
export const PAYMENT_TYPE_POS = "Cash/Check";

export const CARD_TYPES = {
  RH: "RH",
  CC: "CC",
  CC_BTN: "CC_BTN"
};
export const START = "start";

export const COUNT = "count";

export const URL_MAP = {
  "shipping-and-delivery": 0,
  "frequently-asked-questions": 1,
  "order-history.jsp": 2,
  "billing-request-summary": 3,
  "self-scheduler.jsp": 4
};

export const LOGIN_EXPIRATION_MINUTES = 60;
export const SKIP_CALL_USERTYPE = ["registered"];
export const FALLBACK_IMAGE_URL =
  "https://media.restorationhardware.com/is/image/rhis?src=noimage";

export const SELECTED_BRAND_COOKIE = "selected_brand";

export const USER_TYPES = ["REGISTERED", "TRADE", "CONTRACT"];

export const SIP_ID = "sipId";

export const BCT_PATHS = { BC: "/babyandchild", TN: "/teen", RH: "" };

export const DEFAULT_SCHEDULING_VA_CONFIG = {
  isEnabled: false,
  email: "",
  firstName: ""
};

export const DROPSHIP = "dropship";
export const INSTOCK = "instock";

export const PROD_URL = "https://rh.com";

export const PRE_BILL_CATEGORY_5000 = "5000";

export const RH_MEMBER_PROGRAM = "RH Members Program";

export const SWATCH_PRESETS = {
  BOLT: "$PDP-BO-86$",
  LONG_BOLT: "$PDP-LOBO-195$",
  PG_LONG: "$PDP-FUSW-1376$",
  FULL_SWATCH: "$PDP-PGLO-1296$"
};

export const FABRIC_CARE_INSTRUCTIONS_CORE_URL =
  "https://images.restorationhardware.com/content/catalog/us/en/caresheets/Fabric_Care_Instructions_CORE.pdf";
