import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { getLocaleFromCountry } from "./getCountryFromUrl";
import {
  euLanguageToPriceLocaleMapper,
  currencyCodeToCurrencySymbolMapperV2
} from "resources/countries-config.json";
import yn from "yn";

// https://restorationhardware.jira.com/browse/SPAIN-32
const formatAmount = (amount, language: string) => {
  let formattedAmount;
  const modifiedAmount =
    language === "nl" ? amount.replace(/\s+/g, "").replace("€", "") : amount;
  let [integerPart, fractionalPart] =
    language === "fr" || language === "es"
      ? modifiedAmount.split(/(\s+)/).filter(e => e.trim().length > 0)
      : language === "nl"
      ? modifiedAmount.split(".")
      : null;
  if (integerPart.length === 1) {
    if (language === "nl") {
      formattedAmount = `€ ${integerPart}${fractionalPart}`;
    }
    if (language === "fr" || language === "es") {
      formattedAmount = `${integerPart}${fractionalPart} €`;
    }
  } else {
    formattedAmount = amount;
  }
  return formattedAmount;
};

export const formatCurrency = (
  number: Maybe<number> = 0,
  currency: string = "USD",
  showCents: boolean = false,
  addCurrencyPrefix: boolean = false,
  currencySymbol?: string,
  regionLocale?: string,
  formatOptions?: Intl.NumberFormatOptions,
  customFormatter?: (value: number, formatter: Intl.NumberFormat) => string
): string => {
  if (!currency) return "";
  let formatter: Intl.NumberFormat;
  const env = getGlobal("_env");
  const FEATURE_EU_PRICE_FORMAT = !yn(env.FEATURE_EU_PRICE_FORMAT);
  const locale = getLocaleFromCountry();
  const [language, country] = locale?.split("-");
  let euRegionLocale =
    FEATURE_EU_PRICE_FORMAT || (language === "en" && currency === "EUR")
      ? "de-DE"
      : euLanguageToPriceLocaleMapper[language];
  if (regionLocale) {
    euRegionLocale = regionLocale;
  }
  if (currency === "EUR") {
    formatter = new Intl.NumberFormat(euRegionLocale, {
      style: "currency",
      currency,
      minimumFractionDigits: showCents ? 2 : (number || 0) % 1 === 0 ? 0 : 2,
      ...formatOptions
    });
  } else {
    formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: showCents ? 2 : 0,
      ...formatOptions
    });
  }
  const finalNumber = customFormatter
    ? customFormatter(number || 0, formatter)
    : formatter
        .formatToParts(number || 0)
        ?.map(({ type, value }) =>
          type === "currency"
            ? currencySymbol || currencyCodeToCurrencySymbolMapperV2?.[currency]
            : value
        )
        ?.reduce((string, part) => (string + part).trim());
  if (currency === "USD" && addCurrencyPrefix) {
    return finalNumber;
  }
  if (
    !FEATURE_EU_PRICE_FORMAT &&
    (language === "nl" || language === "fr" || language === "es")
  ) {
    return formatAmount(finalNumber, language);
  }
  if (currency === "CAD") {
    return finalNumber?.includes("C$") ? finalNumber : "C" + finalNumber;
  }
  return finalNumber?.replace(/€/, " €");
};
/**
 * @depricated This is a tmporary patch until pricing is unified
 */
export const getCurrencyLabelSymbol = (
  currencyCode: string
): { currencyLabel: string; currencySymbol: string } => {
  const currencySymbol = currencyCodeToCurrencySymbolMapperV2?.[currencyCode];
  return {
    currencyLabel: "",
    currencySymbol: currencySymbol
  };
};
/*return amount without currency symbol */
//return currency in comma separeted form and without currency symbol
export const formatCurrencyWithoutCS = (
  number: number,
  currency: string = "USD",
  showCents: boolean = false
): string => {
  let formatter: Intl.NumberFormat;
  const env = getGlobal("_env");
  const FEATURE_EU_PRICE_FORMAT = !yn(env.FEATURE_EU_PRICE_FORMAT);
  const locale = getLocaleFromCountry();
  const [language, country] = locale?.split("-");
  const euRegionLocale =
    FEATURE_EU_PRICE_FORMAT || (language === "en" && currency === "EUR")
      ? "de-DE"
      : euLanguageToPriceLocaleMapper[language];
  if (currency === "EUR") {
    formatter = new Intl.NumberFormat(euRegionLocale, {
      style: "currency",
      currency,
      minimumFractionDigits: showCents ? 2 : 0
    });
  } else {
    formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: showCents ? 2 : 0
    });
  }
  if (number === null) return ""; //dont return 0 if number is null
  const finalNumber = formatter
    .formatToParts(number)
    ?.map(({ type, value }) => (type === "currency" ? "" : value))
    ?.reduce((string, part) => string + part);
  return finalNumber;
};
