import { useEnv } from "hooks/useEnv";

const env = useEnv();

const { FEATURE_FULL_PLATFORM } = env;

export const shouldReloadForATG = (
  cookies: { [key: string]: any },
  {
    pathname,
    search,
    hash,
    brand
  }: {
    pathname: string;
    search?: string;
    hash?: string;
    brand?: string;
  },
  isFullPlatform: boolean = FEATURE_FULL_PLATFORM
): boolean => {
  if (pathname?.match(/^\/content\/rh/i)) {
    return false;
  }

  function isAEM() {
    if (pathname.includes("model.json")) {
      return true;
    }
  }

  return (
    isAEM() ||
    (!isFullPlatform &&
      cookies?.PF_EXP === "DESKTOP" &&
      !new RegExp(env.PF_DESKTOP_NEW || "").test(
        pathname + (search || "") + (hash || "")
      ))
  );
};
